<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="10" style="margin-bottom: 20px;">
					<el-col :span="12" :xs="24"><el-button type="primary" @click="Refresh">刷新</el-button></el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border stripe>
					<el-table-column prop="day" label="日期"></el-table-column>
					<el-table-column prop="addNum" label="新增用户数"></el-table-column>
					<el-table-column label="无播放视频">
						<template v-slot="scope">
							<span v-if="scope.row.addNum == 0">{{ scope.row.played0 }}(0%)</span>
							<span v-else>{{ scope.row.played0 }}({{ ((scope.row.played0 / scope.row.addNum) * 100).toFixed(2) }}%)</span>
						</template>
					</el-table-column>
					<el-table-column label="视频1次">
						<template v-slot="scope">
							<span v-if="scope.row.addNum == 0">{{ scope.row.played1 }}(0%)</span>
							<span v-else>{{ scope.row.played1 }}({{ ((scope.row.played1 / scope.row.addNum) * 100).toFixed(2) }}%)</span>
						</template>
					</el-table-column>
					<el-table-column label="视频2次">
						<template v-slot="scope">
							<span v-if="scope.row.addNum == 0">{{ scope.row.played2 }}(0%)</span>
							<span v-else>{{ scope.row.played2 }}({{ ((scope.row.played2 / scope.row.addNum) * 100).toFixed(2) }}%)</span>
						</template>
					</el-table-column>
					<el-table-column label="视频3次">
						<template v-slot="scope">
							<span v-if="scope.row.addNum == 0">{{ scope.row.played3 }}(0%)</span>
							<span v-else>{{ scope.row.played3 }}({{ ((scope.row.played3 / scope.row.addNum) * 100).toFixed(2) }}%)</span>
						</template>
					</el-table-column>
					<el-table-column label="视频4次">
						<template v-slot="scope">
							<span v-if="scope.row.addNum == 0">{{ scope.row.played4 }}(0%)</span>
							<span v-else>{{ scope.row.played4 }}({{ ((scope.row.played4 / scope.row.addNum) * 100).toFixed(2) }}%)</span>
						</template>
					</el-table-column>
					<el-table-column label="视频5次">
						<template v-slot="scope">
							<span v-if="scope.row.addNum == 0">{{ scope.row.played5 }}(0%)</span>
							<span v-else>{{ scope.row.played5 }}({{ ((scope.row.played5 / scope.row.addNum) * 100).toFixed(2) }}%)</span>
						</template>
					</el-table-column>
					<el-table-column label="视频6次">
						<template v-slot="scope">
							<span v-if="scope.row.addNum == 0">{{ scope.row.played6 }}(0%)</span>
							<span v-else>{{ scope.row.played6 }}({{ ((scope.row.played6 / scope.row.addNum) * 100).toFixed(2) }}%)</span>
						</template>
					</el-table-column>
					<el-table-column label="视频7次">
						<template v-slot="scope">
							<span v-if="scope.row.addNum == 0">{{ scope.row.played7 }}(0%)</span>
							<span v-else>{{ scope.row.played7 }}({{ ((scope.row.played7 / scope.row.addNum) * 100).toFixed(2) }}%)</span>
						</template>
					</el-table-column>
					<el-table-column label="视频8次">
						<template v-slot="scope">
							<span v-if="scope.row.addNum == 0">{{ scope.row.played8 }}(0%)</span>
							<span v-else>{{ scope.row.played8 }}({{ ((scope.row.played8 / scope.row.addNum) * 100).toFixed(2) }}%)</span>
						</template>
					</el-table-column>
					<el-table-column label="视频9次">
						<template v-slot="scope">
							<span v-if="scope.row.addNum == 0">{{ scope.row.played9 }}(0%)</span>
							<span v-else>{{ scope.row.played9 }}({{ ((scope.row.played9 / scope.row.addNum) * 100).toFixed(2) }}%)</span>
						</template>
					</el-table-column>
					<el-table-column label="视频10次">
						<template v-slot="scope">
							<span v-if="scope.row.addNum == 0">{{ scope.row.played10 }}(0%)</span>
							<span v-else>{{ scope.row.played10 }}({{ ((scope.row.played10 / scope.row.addNum) * 100).toFixed(2) }}%)</span>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'userVideo',
	data() {
		return {
			emptyText: '暂无数据',
			tableData: [],
			serloading: false,
			productId: ''
		};
	},
	created() {
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 新增用户视频数
		this.Cluserlist();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 新增用户视频数
				this.Cluserlist();
			}
		}
	},
	methods: {
		// 新增用户视频数
		Cluserlist() {
			this.serloading = true;
			this.$HTTP.post(
				'userBehavior/queryPlayedData',
				{
					productId: this.productId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 刷新
		Refresh() {
			this.Cluserlist();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
